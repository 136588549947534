import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';

// More info about the menu can be found in the README
export default [{
  name: sitemap._,
  text: i18n('Main'),
  url: '/#' + i18n('_hash:main')
}, {
  name: sitemap.platform,
  text: i18n('Platform'),
  url: i18n('_url:platform'),
  megaMenu: {
    columnCount: 2,
    menuWidth: 1100,
    columns: [
      {
        title: i18n('Platform overview'),
        subTitle: i18n('Flexibility and scalability by design'),
        width: 4,
        items: [
          {
            name:sitemap.platform,
            title: i18n('Data sources'),
            subTitle: i18n('Connecting to millions of vehicles'),
            url: `${i18n('_url:platform')}#${i18n('_hash:data-sources')}`,
            width: 12,
          },
          {
            name:sitemap.platform,
            title: i18n('Seamless backend'),
            subTitle: i18n('Modern cloud engine'),
            url: `${i18n('_url:platform')}#${i18n('_hash:seamless-backend')}`,
            width: 12,
          },
          {
            name:sitemap.platform,
            title: i18n('Ready to use services'),
            subTitle: i18n('From technology to customer adoption'),
            url: `${i18n('_url:platform')}#${i18n('_hash:ready-services')}`,
            width: 12,
          }
        ],
      },
      {
        title: i18n('SaaS tools'),
        subTitle: i18n('Automating key processes'),
        width: 8,
        items: [
          {
            name:sitemap.platform,
            title: i18n('Digital agreements'),
            subTitle: i18n('Simplified data management'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('User management'),
            subTitle: i18n('Flexibility to adapt any setup'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Business Intelligence'),
            subTitle: i18n('Report, analyse and improve'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('In-product onboarding'),
            subTitle: i18n('Automating onboarding flows'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Subscription management'),
            subTitle: i18n('Track usage and manage billing'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Smart assistant'),
            subTitle: i18n('Reducing administration tasks'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
        ],
      },
    ]
  }
}, {
  name: sitemap.solutions,
  text: i18n('Solutions'),
  url: i18n('_url:solutions'),
  megaMenu: {
    columnCount: 2,
    menuWidth: 1100,
    columns: [
      {
        title: i18n('By profile'),
        subTitle: i18n('Get started today'),
        width: 4,
        items: [
          {
            name:sitemap.solutions,
            title: i18n('Fleet management'),
            subTitle: i18n('Optimize your fleet operations'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:fleet-management')}`,
            width: 12,
          },
          {
            name: sitemap.solutions,
            title: i18n('Shared mobility'),
            subTitle: i18n('Increase value and performance '),
            url: `${i18n('_url:solutions')}#${i18n('_hash:mobility')}`,
            width: 12,
          },
          {
            name: sitemap.solutions,
            title: i18n('Flex mobility'),
            subTitle: i18n('Seamless customer experience'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:mobility')}`,
            width: 12,
          },
        ],
      },
      {
        title: i18n('By use case'),
        subTitle: i18n('Exploring new exciting spaces'),
        width: 8,
        items: [
          {
            name:sitemap.solutions,
            title: i18n('Advanced vehicle monitoring'),
            subTitle: i18n('Remote link to vehicles 24/7'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:advanced-monitoring')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Operational efficiency'),
            subTitle: i18n('More sustainable daily operations'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:operational-efficiency')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Maximize uptime'),
            subTitle: i18n('Schedule and automate key processes'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:maximize-uptime')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Driver management'),
            subTitle: i18n('Identify areas to improve'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:driver-management')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Collision detection'),
            subTitle: i18n('Increase safety for drivers and passengers'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:collision-detection')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Keyless access'),
            subTitle: i18n('Provide an unique experience'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:keyless-access')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Fleet administration'),
            subTitle: i18n('Simplify vehicle management'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:fleet-administration')}`,
            width: 6,
          },
          {
            name:sitemap.solutions,
            title: i18n('Transition to EVs'),
            subTitle: i18n('Adopt fleet electrification'),
            url: `${i18n('_url:solutions')}#${i18n('_hash:transition-to-evs')}`,
            width: 6,
          },
        ],
      }, 
    ]
  }
}, {
  name: sitemap.aboutUs,
  text: i18n('About us'),
  url: i18n('_url:about-us')
}, {
  name: sitemap.letsConnect,
  text: i18n('Let\'s connect'),
  url: i18n('_url:lets-connect'),
  callToAction: true 
}];