import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../Typography';
import Refs from './Refs';
import Contact from './Contact';
import styles from './styles.module.sass';

export default () => (
  <Grid container
    alignItems='center'
    alignContent='flex-end'
    className={clsx(styles.footerContainer)}>
    <Grid container
        alignItems='center'
        justifyContent="space-between"
        alignContent='space-between'
        className={styles.footerContent}>
        <Refs/>
    </Grid>
    <Contact />
    <Typography variant='subtitle1' align='center' className={styles.copyrightText}>
      Copyright © {new Date().getFullYear()}, Traffilog Nordic ApS
    </Typography>
  </Grid>
);