export default {
  // Text
  // -- General
  'Language' : 'Sprog',
  'Read more' : 'Læs mere',
  'Try for free' : 'Tilmeld gratis',
  'YES' : 'JA',
  'NO' : 'NEJ',
  'Next' : 'Næste',
  'Name' : 'Navn',
  'No garage' : 'Ingen værksted',
  'Add' : 'Tilføj',
  'Whoops! Something went wrong. Please try again...' : 'Ups! Noget gik galt. Prøv igen senere...',

  // -- -- Industries
  // 'Fleet management' : 'Flådestyring',
  'Leasing' : 'Leasing',
  'Repair shop' : 'Værksted',
  'Dealership' : 'Bilforhandler',
  'Repair shops' : 'Værksteder',
  'Dealerships' : 'Bilforhandlere',

  // -- -- Header
  'Main' : 'Forside',
  'Platform' : 'Platformen',
  'Services' : 'Tjenester',
  'Industries' : 'Kunder',
  'Fleet' : 'Fleet',
  'Features' : 'Funktioner',
  'Pricing' : 'Pris',
  'About us' : 'Om os',
  'Sign up' : 'Tilmeld dig',
  'Why Fleet' : 'Hvorfor Fleet',

  // -- -- -- Header Submenus
 
  // -- -- -- -- Platform menu

  'Platform overview' : 'Platform oversigt', 

  'Connecting to millions of vehicles' : 'Forbindelse til millioner af køretøjer',
  'Seamless backend' : 'Komplet backend',
  'Designed to scale and integrate' : 'Designet til at skalere og integrere',
  'Ready to use services' : 'Klar til brug tjenester',
  'From technology to customer adoption' : 'Fra teknologi til implementering',

  'Automating key processes' : 'Automatisering af nøgleprocesser',

  'Simplified data management' : 'Simplificeret datastyring',
  'Flexibility to adapt any setup' : 'Fleksibilitet til at adoptere hvilket som helst setup',
  'Report, analyse and improve' : 'Rapporter, analyser og forbedre',
  'Automating onboarding flows' : 'Automatiserede onboarding flows',
  'Track usage and manage billing' : 'Spor brug og administrer fakturering',
  'Reducing administration tasks' : 'Reducere administrative opgaver',
  'Flexible administration' : 'Fleksibel administration',
  'Control over support operations' : 'Kontrol over supportoperationer',

  // -- -- -- -- Services menu

  'Vehicle care' : 'Vehicle care',
  'Digital link to customers and vehicles' : 'Digital forbindelse til kunder og køretøjer',
  'Modern fleet management' : 'Moderne flådestyring',
  'Drive' : 'Drive',
  'Car owner\'s best friend' : 'Bilejernes bedste ven',


  // -- -- -- -- Customers menu

  'By profile' : 'Efter profil',
  'Learn more about our services' : 'Lær mere om vores tjenester',

  'Your car\'s best friend' : 'Din bils bedste ven',
  'Fleet manager' : 'Flådeadministrator',
  'Track, maintain and manage vehicles' : 'Spor, vedligehold og administrer køretøjer',
  'Increase customer retention' : 'Øg din kundefastholdelse',
  'Increase the after sales value' : 'Øg eftersalgsværdien',

  'By use case' : 'Efter use case',
  'Exploring new exciting spaces' : 'Udforske nye spændende områder',

  'Remote vehicle monitoring' : 'Fjernmonitorering af køretøj',
  'More efficient daily operations' : 'Mere effektiv daglig drift',
  'Preventive maintenance' : 'Forebyggende vedligeholdelse',
  'Schedule and automate maintenance' : 'Planlæg og automatiser alt vedligeholdelse',
  'Green driving' : 'Grøn kørsel',
  'Identify areas to improve' : 'Identificer områder der skal forbedres',
  'Digital communication' : 'Digital kommunikation',
  'Use video or chat to communicate' : 'Brug video eller chat til at kommunikere',
  'Customer satisfaction' : 'Kundetilfedshed',
  'New tools to keep the customer satisfied' : 'Nye værktøjer for at holde dine kunder tilfredse',
  'Digital documentation' : 'Digital dokumentation',
  'Store and view vehicle\'s history digitally' : 'Gem og se køretøjernes historik digitalt',
  'EV ownership' : 'EV ejerskab',
  'Adapting to the specific needs' : 'Tilpasning til specifikke behov',


  // -- -- -- -- Fleet -> Features sub menu

  'Complete solution' : 'Komplet løsning',
  'Easy and simple to use' : 'Simpelt og nemt at bruge',

  'Different vehicle types' : 'Forskellige type af køretøjer',
  'Connect your car, van or trailer' : 'Forbind din bil, varevogn eller trailer',
  'Fleet dashboard' : 'Fleet dashboard',
  'For the administrator' : 'For administratoren',
  'App for drivers' : 'App for ansatte',
  'For the employee on the road' : 'For medarbejderen der er på farten',


  // -- -- -- -- Fleet -> Why Fleet sub menu

  'Case studies' : 'Case studier',

  'Construction' : 'Byggeri',
  'Downtown ApS' : 'Downtown ApS',
  'Education' : 'Uddannelse',
  'Zealand Erhvervsakademi' : 'Zealand Erhvervsakademi',

  'Benefits' : 'Fordele',
  'Exploring the benefits' : 'Undersøg fordelene',

  'Reporting' : 'Rapportering',
  'Schedule relevant reports' : 'Planlæg relevante rapporter',
  'Preventive maintenace' : 'Forebyggende vedligeholdelse',
  'Schedule and automate all maintenance' : 'Planlæg og automatiser al vedligeholdelse',
  'Increased safety for employees' : 'Øget sikkerhed for ansatte',
  'Identify issues remotely' : 'Identificer problemer på afstand',
  'Driving and visiting history' : 'Kørsel og besøgshistorik',
  'View vehicle\'s driving history' : 'Se køretøjets kørselshistorik',
  'Less administrative work' : 'Mindre administrativt arbejde',

  // -- -- Call to Action
  'Get started with OBI+ today' : 'Kom i gang med OBI+ i dag',
  'Get started' : 'Kom i gang',
  'Email address' : 'Email adresse',
  'Product tour' : 'Produkt tour',
  'Consultation' : 'Konsultation',
  'Trial' : 'Prøveperiode',
  'Perfect! We will contact you soon.' : 'Perfekt! Vi vil kontakte dig snart.',

  // -- -- Footer
  'The company' : 'Virksomheden',
  'Resources' : 'Ressourcer',
  'Partners' : 'Partnere',
  'Terms' : 'Brugerbetingelser',
  'Privacy' : 'Fortrolighedspolitik',

  // -- -- 404: Not found
  'NOT FOUND' : 'IKKE FUNDERET',
  'This page does not exist.' : 'Denne side findes ikke',

  // -- Main

  // -- -- Hero
  'OBI+ connected car services' : 'OBI+ connected car tjenester',
  'The platform that' : 'Platformen der',
  'enables growth' : 'giver vækstmuligheder',
  'empowers innovation' : 'driver innovation',
  'drives loyalty' : 'driver loyalitet',
  'delivers insights' : 'leverer indsigt',
  'A safer, better smarter future' : 'En sikrere, bedre og smartere fremtid',
  'powered by data-driven services.' : 'Leveret af datadrevne tjenester.',

  // -- -- Why
  'All needed to launch and scale a connected car service.' : 'Alt du har brug for at starte og skalere en connected car tjeneste.',
  'Choose the service for your business' : 'Vælg en tjeneste til din virksomhed',
  'Get access to your admin account' : 'Få adgang til din admin konto',
  'Select the model that fits your customers' : ' Vælg den model, der passer til dine kunder',
  'Launch the service with support form OBI+' : ' Lancer tjenesten med hjælp fra OBI+',

  // -- -- Solution
  'End-to-end solution' : 'Komplet løsning',
  'Run your service the way you need it. From plug & play data sources to ready to use connected car services. At OBI+ we are committed to continuously adding capabilities to the OBI+ platform and connecting it to the leading technology of tomorrow.' : 'Benyt din tjeneste på den måde, du har brug for det. Fra plug & play datakilder til connected car tjenester, der kan benyttes med det samme. Hos OBI+ er vi forpligtet til løbende at tilføje funktioner til OBI+ platformen og forbinde den til fremtidens førende teknologi.',
  'Data sources' : 'Datakilder',
  'Standardization' : 'Standardisering',
  'Administration' : 'Administration',
  'Suite of services' : 'En række tjenester',
  'Integration' : 'Integration',

  // -- -- Value
  'Create value for customers' : 'Skab værdi for kunderne',
  'Expand your service portfolio quickly and cost-effectively. We are true believers in developing connected car services that are useful for everyone. Choose any of the ready-to-use services to get started right away. All services could work either as a standalone solution or be seamlessly integrated together or into external systems.' : 'Udvid din portefølje af tjenester hurtigt og omkostningseffektivt. Vi tror på udvikling af connected car tjenester der er nyttige for alle. Vælg en af de klar-til-brug tjenester for at komme i gang med det samme. Alle tjenester kan fungere enten som en enkeltstående løsning eller integreres problemfrit sammen eller i eksterne systemer.',

  // -- -- Vehicle Care
  'Helping professionals to create a link to vehicles and customers to reach a higher service standard' : 'Hjælper fagfolk til at oprette forbindelse til køretøjer og kunder for at opnå højere servicestandard',

  // -- -- Fleet
  'Web-based fleet management service that makes it easy to track, maintain, and manage vehicles.' : 'Webbaseret flådestyringstjeneste, der gør det nemt at spore, vedligeholde og administrere køretøjer.',

  // -- -- Driver App
  'Enabling seamless digital interactions for car owners through connected car technology and multi-feature mobile applications' : 'Muliggør nemme digitale interaktioner for bilejere gennem connected car teknologien og multifunktionelle mobile applikationer',

  // -- -- Industries
  'Customers across multiple industries' : 'Kunder på tværs af flere brancher',
  'OBI+ helps all businesses ready to adopt connected car technology and services, regardless of size. Whether you are searching for innovative ways to drive customer retention and loyalty, optimize your business, or make vehicles more attractive, you get the tools to work for you.' : 'OBI+ hjælper alle virksomheder, uanset størrelse, der er parate til at anvende connected car teknologi og -tjenester. Uanset om du søger efter innovative måder, hvorpå du kan fastholde kunderne og deres loyalitet, optimere din virksomhed eller gøre køretøjer mere attraktive, får du nu værktøjerne til at arbejde for dig.',

  // -- -- Advantages
  'What are your advantages' : 'Hvad er dine fordele',
  'Interoperability' : 'Interoperabilitet',
  'All applications work together seamlessly to provide a complete service offering.' : 'Alle applikationer virker problemfrit sammen for at levere et komplet tjenestetilbud.',
  'Fast time to market' : 'Hurtig levering til markedet',
  'Off-the-shelf applications ready to be used with the possibility for white labeling if required.' : 'Alle applikationer klar til brug og med mulighed for white labeling.',
  'Easy deployment' : 'Nem implementering',
  'Seamless deployment and operation designed to facilitate the needs of every customer or partner.' : 'Problemfri implementering og betjening designet til at gøre det lettere for hver kunde eller partner.',
  'Leveraging existing IT' : 'Udnyttelse af eksisterende IT',
  'Elaborate APIs that make it easy to integrate data into existing systems.' : 'Udnyt API’er, der gør det nemt at integrere data i eksisterende systemer.',
  'Get started playbook' : 'Kom i gang playbook',
  'Cooperating together with customers and partners to ensure smooth adoption.' : 'Samarbejde med kunder og partnere for at sikre en smidig vedtagelse.',
  'Specialized expertise' : 'Specialiseret ekspertise',
  'Years of experience with connected services leads to specific know-how on important topics.' : 'Mange års erfaring med tilsluttede tjenester fører til specifik viden om vigtige emner.',

  // -- Platform

  // -- -- SaaS tools
  'SaaS tools' : 'SaaS værktøjer',
  'In addition to the powerful platform, we are providing a set of software tools to support key processes for implementing, managing, and improving the operations of our partners.' : 'Ud over den kraftfulde platform leverer vi et sæt softwareværktøjer til at understøtte nøgleprocesser til implementering, styring og forbedring af vores partners drift.',
  'Digital agreements' : 'Digitale aftaler',
  'Agreements engine adapting to the service and use case' : 'Et værktøj der holder styr på aftaler og tilpasser sig til tjenesten og use case',
  'Business intelligence' : 'Business intelligence',
  'A customisable BI setup to help keeping track and improving.' : 'En tilpasset BI-opsætning, der hjælper med sporing og forbedring',
  'Subscription management' : 'Abonnementsstyring',
  'Adjustable subscriptions to adapt to the selected model and ease billing.' : 'Tilpassede abonnementer for at kunne passe til den valgte model og for at lette faktureringen',
  'User management' : 'Brugeradministration',
  'Set user management on auto-pilot and interfere whenever required' : 'Indstil brugeradministration på auto-pilot og gør noget når det er nødvendigt',
  'In-product onboarding' : 'Onboarding i produktet',
  'Secure a great start for customers by utilising in-product onboarding flows' : 'Sikre en god start for kunderne ved at bruge onboarding flow i produktet',
  'Smart assistant' : 'Smart assistent',
  'Automation of administrative and reporting tasks to ease operations' : 'Automatisering af administrative og rapporteringsopgaver for at lette driften',
  'Access management' : 'Adgangsstyring',
  'Flexibility in administrating access grants to adapt to any structure.' : 'Fleksibilitet til at administrere bevillinger for tilpasning for enhver struktur',
  'Support overview' : 'Supportoversigt',
  'Having control of support operations when actions are necessary' : 'At have kontrol over supportoperationer, når handlinger er nødvendige',

  // -- -- Leverage links
  'Leverage links to data sources' : 'Udnyt links til datakilder',

  // -- -- Plug & Play
  'Plug & play hardware and software' : 'Plug & Play hardware og software',
  'With more than 1+ billion older vehicles driving on the roads and the diversity of use cases, the need for additional hardware is present.' : 'Med mere end 1 milliard ældre køretøjer, der kører på vejene, og mangfoldigheden af use cases, er behovet for ekstra hardware til stede.',
  'We have made the process of establishing the desired link to a vehicle truly plug & play.  This includes both the required hardware and software to get connected in the fastest way and the supporting processes to enhance the data quality.' : 'Vi har gjort processen med at etablere den ønskede forbindelse til et køretøj til en simpel plug & play løsning. Dette inkluderer både den nødvendige hardware og software for at få forbindelse på den hurtigste måde og de understøttende processer for at forbedre datakvaliteten.',

  // -- -- Direct Link
  'Direct hardware-free link to major brands' : 'Direkte hardwarefri forbindelse til store mærker',
  'Connecting to millions of vehicles through our partner network is already enabled for every single customer and partner eligible for it.' : 'Forbindelse til millioner af køretøjer via vores partnernetværk er allerede tilgængeligt for hver eneste kunde og partner, der er berettiget til det.',
  'The OBI+ platform is taking care of all steps required for you to gain access to the vehicles, thus making it easier than ever to be connected.' : 'OBI+ platformen tager sig af alle nødvendige skridt for at få adgang til køretøjerne, hvilket gør det lettere end nogensinde at være forbundet.',
  
  // -- -- Be Connected
  'Explore our services' : 'Udforsk vores tjenester',

  // -- -- Flowchart
  'Web-based platform to enable connected car services' : 'En connected car platform, der tilbyder en række løsninger til køretøjer',
  
  // -- Services

  // -- -- Hero
  'Explore the digital world powered by OBI+' : 'Udforsk den digitale verden leveret af OBI+',

  // -- -- Overview
  'OBI+ Platform overview' : 'OBI+ platform oversigt',
  'It is a powerful platform designed to enable connected car services at scale. All a business needs to expand its portfolio quickly and cost-effectively.' : 'En platform udviklet til at muliggøre connected car tjenester i stor skala. Enhver virksomhed har brug for at udvide sin portefølje hurtigt og omkostningseffektivt.',
  
  // -- -- Be connected
  'Be connected tomorrow' : 'Bliv connected i morgen',
  'Suite of applications used by you, your customers, partners, or service providers.' : 'En række applikationer som bruges af dig, dine kunder, partnere eller tjenesteudbydere.',

  // -- -- Vehicle Care
  'A web-based service for vehicle management' : 'En webbaseret tjeneste til administration af køretøjer',
  // -- -- -- Features
  'Vehicle profile' : 'Køretøjsprofil',
  'Vehicle information available within a single page. Quick sub-menus to alerts, service and repair logbook, and communication with users.' : 'Køretøjets oplysninger til rådighed på en enkelt side. Hurtige undermenuer til advarsler, service og reparationslogbog og kommunikation med brugerne.',
  'Communication' : 'Kommunikation',
  'Start a conversation with a single click. A convenient and easy way to assist customers who experience issues or want to schedule a time for a service.' : 'Start en samtale med et enkelt klik. En praktisk og nem måde at hjælpe kunder, der oplever problemer eller ønsker at planlægge et tidspunkt for en service.',
  'Reports' : 'Rapporter',
  'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others.' : 'Planlagte rapporter, der peger ens opmærksomhed på vigtige begivenheder såsom motorlys, kommende service og andre.',
  'Service and repair logbook' : 'Service og reparationslogbog',
  'Service overview' : 'Hold styr på service og reparation',
  'Keep track of all performed and upcoming services. Attach related documentation in a digital format.' : 'Hold styr på udførte og kommende services. Vedhæft det relevante.',
  'Easy overview' : 'Nem oversigt',
  'All vehicles assigned to the account can be easily accessed. Quick navigation and filtering assist in the search process.' : 'Alle køretøjer der er tilknyttet kontoen er nemt tilgængelige. Hurtig navigation og filtrering hjælper med søgeprocessen.',
  'Notifications' : 'Notifikationer',
  'Receive real-time notification for events that require your attention. Adjust the preferences to your individual needs and processes.' : 'Modtag realtid notifikationer for begivenheder, der kræver din opmærksomhed. Juster præferencerne til dine individuelle behov og processer.',

  // -- -- Fleet
  'A fleet management service suitable for a variety of customer segments' : 'En flådestyringstjeneste, der er egnet til forskellige kundesegmenter.',
  // -- -- -- Features
  'Fleet management' : 'Flådestyring',
  'Fleet management ' : 'Flådeoversigt',
  'Fleet overview' : 'Flådestyring',
  'Easy and simple fleet monitoring that gives you an overview of your fleet.' : 'Nem og enkel flåde monitorering, der giver et overblik over flåden.',
  'Automatic trip logging' : 'Automatisk kørselsregistrering',
  'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.' : 'Samtlige køretøjers ture er automatisk sporet. Relevant information vedrørende afgang/ankomst, adresser, varighed, længde og kørselstype er tilgængelig, også i et historisk overblik.',
  'Fleet statistics' : 'Flådestatistik',
  'The latest statistics about your fleet are always available. Get valuable insights about your fleet such as utilization, fuel consumption, and more.' : 'De seneste statistikker vedrørende flåden er altid tilgængelige. Få værdifuld indsigt såsom udnyttelse, brændstofforbrug og mere.',
  'Easy communication' : 'Nem kommunikation',
  'Through the in-built chat the user can be in contact with the service provider (e.g. mechanic) and stay always updated about important events that occur.' : 'Gennem den indbyggede chat funktion kan brugeren være i direkte kontakt med tjenesteudbyderen (f.eks mekanikeren) og altid være opdateret på vigtige begivenheder der opstår.',
  'Personalized reports' : 'Personlige rapporter',
  'You can enable relevant reports such as notification about overspeeding, driving logs, geofencing, or usage of the vehicles after working hours.' : 'Mulighed for at oprette relevante rapporter såsom notifikationer vedrørende fartoverskridelser, kørebog, geofencing, eller brug af køretøjerne efter endt arbejdstid.',
  'Geofencing' : 'Geofencing',
  'Create different geofences to be notified when vehicles enter or exit a certain area to keep track of the daily workflow or avoid unauthorized usage.' : 'Opret forskellige geofences, og få besked, når køretøjer kører ind eller forlader et bestemt område for at holde styr på den daglige arbejdsgang eller undgå uautoriseret brug.',

  // -- -- Driver App
  'A connected car service valuable for every driver with applications available on both iOS and Android devices' : 'En connected car tjeneste, der er værdifuld for enhver bilist. Applikationen er tilgængelig på både iOS- og Android-enheder',
  // -- -- -- Features
  'Vehicle overview' : 'Køretøjsoversigt',
  'An easy overview for each driver. Car location and walking directions. Vehicle condition and information in case of alerts. Fuel level and mileage information.' : 'Nem oversigt til hver bruger af køretøjet. Køretøjets tilstand og information i tilfælde af indberetninger. Oplysninger om brændstofniveau og kilometertal.',
  // 'Automatic trip logging' : 'Automatisk rejseregistrering',
  // 'All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.' : 'Alle køretøjernes rejser er automatisk sporet. Relevant information vedrørende afgang/ankomst, adresser, varighed, længde og type rejse er tilgængelig for dig, som historisk data og kan nemt tilgås i din kalender oversigt.',
  'Trip categorization and quick notes' : 'Kategorisering af kørsel og hurtige noter',
  'Select a default trip categorization or switch the purpose of a trip with a single tap. Add quick notes on a trip to easily remember which customer you visited or which event you attended.' : 'Vælg en standard kategorisering af tur eller skift formålet med en tur med et enkelt tryk. Tilføj hurtige noter til en tur for nemt at huske, hvilken kunde du har besøgt, eller hvilken begivenhed du deltog i.',
  'Driving logbook' : 'Kørebog',
  'It gets easy once you have all trips automatically tracked. You can export a driving logbook report at any time with a single click.' : 'Det bliver nemt, når du automatisk har sporet alle dine ture. Du kan eksportere en kørebog på hvilket som helst tidspunkt med et enkelt klik.',
  // 'Communication' : 'Kommunikation',
  'Through the in-built chat you can be in contact with your service provider (e.g. mechanic). In this way, you will be always updated about important events that occur.' : 'Gennem den indbyggede chat funktion kan du være i direkte kontakt med din mekaniker eller administrator. Dermed kan du altid være opdateret på de vigtige ændringer der er foretaget, så du ved hvornår dit køretøj er klar til at blive hentet.',
  'Choose between multiple vehicles' : 'Vælg mellem flere køretøjer',
  'Have the flexibility to choose between one or more vehicles that you are driving. It makes it easier to keep track of vehicle utilization and management.' : 'Du har fleksibilitet til at vælge mellem et eller flere køretøjer, som du kører. Det gør det lettere at holde styr på køretøjets anvendelse og styring.',

  // -- -- Industries
  'See how OBI+ services can empower your business' : 'Se hvordan OBI+ tjenester kan styrke din virksomhed',

  // -- Fleet management

  // -- -- Main

  // -- -- -- Hero
  'Overview of your vehicles in the easiest way' : 'Få overblik over dine køretøjer på den nemmeste måde',
  'Try it for free' : 'Se Fleet i aktion',
  'Hundreds of companies already use Fleet by OBI+' : 'Hundredvis af danske virksomheder bruger allerede OBI+ Fleet',

  // -- -- -- Why
  'Fleet is a simple to use fleet management solution for everyone within the company' : 'Fleet er en let anvendelig flådestyringsløsning til alle i virksomheden',
  'Manage all vehicles' : 'Administrer køretøjer',
  'Automate trip-logging' : 'Automatiser kørselsregistrering',
  'Receive personalized reports' : 'Modtager skræddersyede rapporter',
  'Make repair and maintenance of cars easy' : 'Gør reparation og vedligeholdelse af biler nemmere',

  // -- -- -- Overview
  'Dashboard overview' : 'Dashboard oversigt',
  'Forget the boring paperwork and use smart notifications' : ' Glem det kedelige papirarbejde og brug smarte notifikationer',
  // -- -- -- -- Features
  'Automatic data entry and simple historical overview' : 'Automatisk dataindtastning og historisk overblik over bilerne',
  'Relevant fleet statistics' : 'Relevant flådestatistik',
  'Receive automatic and personalized reports specific to your fleet usage' : 'Modtag automatiske og skræddersyede rapporter der er specifikke for din flåde',

  // -- -- -- Mobile App
  'Show your employees trust while you keep the control' : 'Vis dine medarbejdere tillid, mens du bevarer kontrollen',
  'Mobile app for every driver' : 'Mobil-app til hver bruger af køretøjet',
  // -- -- -- -- Features
  'Download a mobile application to view journeys and add notes' : 'Download en mobil-app for at se kørsel og tilføj noter',
  'Automatic trip logging and categorization' : 'Automatisk registrering og kategorisering af kørsel',
  'Receive key vehicle notifications and communicate with your colleagues' : 'Modtag vigtige køretøjsmeddelelser og kommuniker med dine kollegaer',

  // -- -- -- Savings
  'Save your time and avoid unpleasant situations' : 'Spar tid og undgå ubehagelige situationer',
  'See how the company vehicles are being used' : 'Se hvilke fordele du får',
  // -- -- -- -- Features
  'Show customers that a job has been done to avoid discussions' : 'Vis kunder at der er udført et arbejde for at undgå diskussioner',
  'Communicate with employees while in the field' : 'Kommuniker med medarbejdere mens de er på farten',
  'Define your own tags that pass the company\'s internal communication' : 'Definer dine egne tags, der videregiver virksomhedens interne kommunikation',
  
  // -- -- -- -- UsedBy
  'Customers across all industries' : 'Kunder på tværs af alle brancher',
  'We help all businesses, regardless of size, to track their vehicles, reduce costs, increase productivity, increase safety, coordinate better repair and maintenance, and more.' : 'Vi hjælper alle virksomheder uanset størrelse med sporing af biler og med bedre koordinering af reparation og vedligeholdelse. Derudover hjælper vi med at omkostningerne reduceres samt at sikkerheden øges.',
  // -- -- -- -- -- Icons
  'Craftsmen' : 'Håndværk & byggeri',
  'Leasing & rental' : 'Leasing & biludlejning',
  'Wholesalers & retailers' : 'Grossister & detailhandel',
  'Plumbing' : 'VVS',
  'Transport & logistics' : 'Transport & logistik',
  'State & municipality' : 'Stat & kommune',
  'Agriculture' : 'Landbrug',
  'Sales & services' : 'Salg & tjenester',

  // -- -- -- -- Sponsors
  'Hundreds of companies use already Fleet by OBI+' : 'Hundredvis af danske virksomheder bruger allerede OBI+ Fleet',
  
  // -- -- Features
  
  // -- -- -- Hero
  'A complete fleet management solution' : 'En komplet flådestyringsløsning af køretøjer',
  'Fleet is a simple to use fleet management solution for everyone within the company. Have a full overview of your fleet of vehicles, forget about paperwork and let your employees be more efficient.' : 'Fleet er en enkel at bruge flådestyringsløsning til alle i virksomheden. Få et fuldt overblik over din køretøjsflåde, glem papirarbejdet, og lad dine medarbejdere blive mere effektive.',

  // -- -- -- Vehicles
  'Connect any vehicle type with plug&play adapter or hardware-free' : 'Forbind enhver type køretøj med en plug & play adapter eller uden hardware',
  'Connect your car, van, trailer or other vehicles to the Fleet service. We have made it easy to get started and take advantage of all relevant features.' : 'Forbind din bil, varevogn, trailer eller andre køretøjer til din flådestyring. Vi har gjort det nemt at komme i gang og drage fordel af alle de nye funktioner.',

  // -- -- -- Fleet dashboard -- -- see Text/Services/Fleet management

  'Dashboard' : 'Betjeningspanel',
  'dashboard' : 'betjeningspanel',
  'Fleet web dashboard' : 'Fleet web dashboard',

  // -- -- -- Driver App -- -- see Text/Services/Driver App

  // -- -- Why Fleet?

  // -- -- -- Hero
  'One solution to simplify the vehicle related tasks' : 'En løsning til at forenkle de køretøjsrelaterede opgaver.',
  'Save time. Improve efficiency. Easy to use.' : 'Spar tid. Øg effektiviteten. Nemt at bruge.',

  // -- -- -- FORDELE
  'Advantages' : 'Fordele',
  'Optimize your fleet' : 'Optimer din flåde',
  'No matter the size of your fleet, you can use information that you never had before. It is time to utilize data that matters to increase cost savings and driver retention rates.' : 'Få overblik over alle bilerne. Uanset størrelsen på din flåde, kan du få informationer som du aldrig har haft før. Det er tid til at udnytte data, der betyder noget, for at øge besparelsen og førerens tilbageholdelsesrate.',
  'Increase productivity' : 'Øget produktivitetet',
  'Know where your vehicles are, whether they are moving or parked. Send over the closest driver to perform a job in the area. Access historical data about the utilization of the fleet and individual vehicles.' : 'Man ved hvor køretøjerne er, uanset om de er i bevægelse eller om de holder parkeret. Send føreren der er tættest på i området til at udføre arbejdet. Historisk data omkring udnyttelse af flåden og individuelle køretøjer.',
  'Receive more professional service' : 'Få en mere professionel service',
  'Better management of your assets through remote supervision and coordination of key events. 8 out of 10 employees forget to notify a manager about key car-related events (e.g. service/engine light on).' : 'Bedre styring af aktiver gennem koordinering af vigtige begivenheder. 8 ude af 10 af de ansatte glemmer at give besked til sin leder når der opstår et problem. (fx service/motor varsellampe)',
  'Increase the safety of your drivers' : 'Øget sikkerhed for dine medarbejdere',
  'Get peace of mind by knowing that your drivers and vehicles are safe to drive. Have a guardian angel (your mechanic) that can translate any issues that occur in simple words' : 'Vær sikker på, at medarbejderne kan køre sikkert videre. Hav en skytsengel (din mekaniker) der kan hjælpe dig, med et hvilket som helst problem, der måtte opstå.',
  'Prevent unauthorized mobility' : 'Undgå uautoriseret mobilitet',
  'The service is definitely something for you if your goal is to reduce insurance and maintenance costs or to receive timely alerts and information about unauthorized usage of the vehicles.' : 'Tjenesten er helt bestemt noget for dig, hvis dit mål er at reducere forsikrings- og vedligeholdelsesomkostninger, modtage rettidige varsler og få informationer om uautoriseret brug af køretøjerne.',
  'Automate data entry' : 'Automatisk dataindtastning',
  'Eliminate manual mileage tracking to save time and increase the productivity of your drivers. Each driver or fleet manager can categorize the trips and add relevant notes.' : 'Eliminer manuelt kilometertal registrering for at spare tid og øg medarbejdernes produktivitetet. Hver medarbjeder eller flådeleder kan kategorisere turene og tilføje relevante noter.',

  // -- -- -- Motivation
  'Make repair and maintenance easy' : 'Gør reparation og vedligeholdelse mere simpelt',
  'Why complicate the administration of your vehicles when the professionals can help you in every step of the way?' : 'Hvorfor gøre administrationen mere kompliceret, når professionelle kan hjælpe dig i hvert skridt på vejen?',
  'Be contacted when key events like maintenances, inspections (syn), change of tires and others should be performed.' : 'Bliv kontaktet når begivenheder som vedligeholdelse, syn, udskiftning af dæk og andet skal udføres.',
  'Maintenance' : 'Vedligeholdelse',
  'Inspections' : 'Inspektioner',
  'Tire changes' : 'Dæskift',
  'Be contacted when key events should be performed.' : 'Bliv kontaktet når begivenheder som vedligeholdelse, syn, udskiftning af dæk og andet skal udføres.',
  'Your employees should not be held accountable for forgetting to share that the engine lamp is on.' : 'Dine medarbejdere skal ikke holdes ansvarlige hvis de glemmer at dele når en motorlampe lyser.',
  // -- -- -- -- Tiles
  'Safer vehicles to drive' : 'Mere sikre køretøjer at køre.',
  'Communicate with your preferred garage at the right time.' : 'Kommuniker med dit foretrukne værksted på det rigtige tidspunkt.',
  'Receive help in breakdowns' : 'Modtag hjælp i tilfælde af nedbrud',

  // -- -- Pricing
  'What do you want to connect to?': 'Hvad vil du gerne tilslutte?',
  'Cars' : 'Biler',
  'Vans' : 'Varevogne',
  'Trucks' : 'Lastbiler',
  'Trailers' : 'Trailere',

  'How many vehicles do you want to track?' : ' Hvor mange biler ønsker du at spore?',
  'Number of vehicles' : 'Antal køretøjer',

  'Do you want to select a preferred garage?' : 'Ønsker du at tilslutte dit foretrukne værksted?',
  'No, I do not want to select a garage.' : 'Nej, jeg ønsker ikke at tilslutte et værksted',
  'Yes, I do have a preferred garage' : 'Ja, jeg ønsker at tilslutte et værksted',

  'One of the unique aspects of our service is the possibility to select a preferred garage to make vehicle management easier for you.' : ' En af de unikke aspekter af vores service er muligheden for at vælge et værksted for at gøre køretøjsadministrationen lettere for dig.',
  'Write the name of the garage or select it from the list' : 'Skriv navnet på garagen, eller vælg det fra det sidste',

  'Perfect. We are ready to send you a personal offer.' : 'Perfekt. Vi er klar til at sende dig et personligt tilbud',

  'Which email should we send the price request to?' : 'Hvilken email skal vi sende prisanmodningen til?',
  'Email' : 'Email',
  'Telephone number (optional)' : 'Telefonnummer (valgfrit)',
  'Send me the offer' : 'Send mig et tilbud',
  'Thank you!' : 'Tak!',

  'By clicking \'Send me an offer\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.' : 'Ved at klikke på \'Send mig et tilbud\', giver du samtykke til at, OBI Plus ApS må gemme og behandle personlige oplysninger, du har angivet herover, til at levere det, du har anmodet om.',
  'You can unsubscribe from these messages at any time. For more information on how to unsubscribe, about our practices, and how we are committed to protecting and respecting your privacy, read our' : 'Du kan afmelde disse meddelelser til enhver tid. For mere information om, hvordan man afmelder, om vores praksis, og hvordan vi er engageret i at beskytte og respektere dit privatliv, kan du læse vores',
  'Terms of Use and Privacy Policy' : 'Brugerbetingelser og fortrolighedspolitik',

  'Our sales team is currently preparing the offer for you.' : 'Vores salgsteam forbereder i øjeblikket tilbuddet til dig.',
  'While we finish, you can' : 'Mens vi færdiggøre, kan du',
  'Try Fleet for free' : 'Prøve Fleet gratis',
  'Book a demo' : 'Booke en demo',
  'Contact us' : 'Kontakte os',

  // -- Drivers

  'Drivers' : 'Bilister',
  'Driver' : 'Bilist',

  // -- -- Main

  // -- -- -- Hero
  'Your car\'s best friend.' : 'Din bils\n bedste ven.',
  'Safer driving. Convenient experience.' : 'Sikker kørsel. Bekvem oplevelse.',

  // -- -- -- Pitching section
  'Connected car' : 'Connected car',
  'Innovative technology delivered in a simple and affordable way.' : 'Innovativ teknologi leveret på en enkel og overkommelig måde',
  'Car app' : 'Bil-app',
  'A better way to keep track of your car.' : 'En bedre måde at holde et overblik over din bil på.',
  'Smart link' : 'Smart forbindelse',
  'Peace of mind and minimal effort on car-related tasks' : 'Tryghed og minimal indsats på bil-relaterede opgaver',

  // -- -- -- Overview
  'Easy car overview' : 'Nem oversigt over bilen',
  'All car information in your hands. Follow parking, fuel level, and history of service and repairs.' : 'Al bilinformation i dine hænder. Følg parkering, brændstofniveau samt kommende og tidligere service og reparationer.',
  'Peace of mind' : 'Slip for bekymringer',
  'Have a safer and more reliable car. Follow changes in your car\'s condition and be advised by your preferred workshop.' : 'Få en mere sikker og pålidelig bil. Følg med i ændringer i bilens tilstand og få gode råd fra dit foretrukne værksted.',
  'Stop wasting time on keeping track of your trips. All are tracked automatically and all relevant information is available.' : 'Stop med at spilde tid på at holde styr på dine ture. Samtlige ture spores automatisk, og alle relevante oplysninger er tilgængelige.',
  'Hassle-free service and repair experience' : 'Problemfri service og reparationsoplevelse',
  'Convenient way to communicate with your workshop anytime you want. Always updated about important events that occur.' : 'Praktisk måde at kommunikere med dit værksted når som helst. Altid opdateret med vigtige begivenheder, der opstår.',

  // -- -- -- Advantages
  'How it helps you' : 'Hvordan det hælper dig',
  'Safer and reliable car' : 'Sikker og pålidelig bil',
  'No worries in a critical situation' : 'Ingen bekymringer i en kritisk situation',
  'Avoid expensive repairs' : 'Undgå dyre reparationer',
  'Minimal efforts on car tasks' : 'Minimal indsats på bilopgaver',
  'Online dominated experience' : 'Onlinedomineret oplevelse',
  'Save time' : 'Spar tid',

  // -- -- -- Pricing
  'The price?' : 'Prisen?',
  'Less than you pay for a cup of coffee.' : 'Mindre end hvad du betaler for en kop kaffe.',
  'kr/m' : 'kr./måned',

  // -- -- -- Compare
  'How is our service different?' : 'Hvordan er vores tjeneste anderledes?',
  'Compare' : 'Sammenlign',
  'Service & repair experience' : 'Service & reparation oplevelse',
  'The old way' : 'Den gamle måde',
  'Service light or engine light on.' : 'Servicelampen eller motorlampen er tændt',
  'The car tells me to do something. I\'ll do it later.' : 'Bilen fortæller mig, at der skal gøres noget. Jeg vil gøre det senere.',
  '30 day later. There is something to do about my car.' : '30 dage senere. Der er noget skal gøres ved min bil.',
  'Time sure flies. Should have left a note.' : 'Tiden flyver afsted. Skulle have efterladt en note.  ',
  'I should remember to call the workshop' : 'Jeg skal huske at ringe til værkstedet.',
  'Something should be done.' : 'Der skal gøres noget.',
  'Checking workshop\'s available hours. They work until 16:30 ... it\'s 17:00 now.' : 'Checker hvor længe værkstedet har åbent. De har åbent til kl. 16:30 … kl. er 17:00 nu',
  'Finally something is to be done.' : 'Endelig skal der gøres noget.',
  '3 days later. I need to call or visit. Might need to take time off from work.' : '3 dage senere. Jeg skal ringe eller besøge værkstedet. Bliver måske nødt til at tage fri fra arbejde.',
  'Too much work... wondering why it takes so long.' : 'For meget arbejde ... gad vide, hvorfor det tager så lang tid.',
  '33+ days later. Car is finally at the shop.' : '33+ dage senere. Bilen er endelig på værkstedet.',
  'We hope that you didn\'t have your engine light on for this period of time... its either unsafe or might get expensive.' : 'We hope that you didn\'t have your engine light on for this period of time... its either unsafe or might get expensive.',

  'The new way' : 'Den ny måde',
  'The car tells me to do something. My phone will handle it or remind me later.' : 'Bilen fortæller mig, at der skal gøres noget. Min telefon vil håndtere det eller minde mig om det på et senere tidspunkt.',
  'Workshop is notified right away' : 'Dit værksted bliver underrettet med det samme',
  'Something is being done already.' : 'Der er noget der skal gøres.',
  'I don\'t need to worry' : 'Du behøver ikke at bekymre dig',
  'You are contacted by your workshop with information about it and agree on a convenient time for you to visit.' : 'Du bliver kontaktet af dit værksted med information om det og aftaler et passende tidspunkt for dig at besøge dem.',
  'All is settled in 1 day. Peace of mind.' : 'Alt er klaret i løbet af en dag.',
  'Avoid making the issue more severe with time' : 'Undgå at problemet udvikler sig til noget større',
  'Minimal efforts and hassle-free experience' : 'Minimal indsats og problemfri oplevelse',
  'You know if it\'s safe to drive meanwhile' : 'Du er klar over hvorvidt det er sikkert at køre videre',

  'Logbook services' : 'Kørebog tjenester',
  'Logbook service' : 'Kørebog',
  'Trip data collection' : 'Indsamling af kørselsdata',
  'Trip registration' : 'Kørselsregistrering',
  'Export reports' : 'Eksporter rapporter',
  'Hardware' : 'Hardware',
  'Commitment' : 'Binding',
  'Safety through connected car technology' : 'Sikkerhed gennem connected car teknologien',
  'Service & repair link' : 'Service & reparation forbindelse',
  'Added value' : 'Merværdi',
  'Find my car' : 'Find min bil',
  'Stats' : 'Statistik',
  'Tips' : 'Tips',
  'Price' : 'Pris',
  'Automated' : 'Automatiseret',
  'Always unlimited' : 'Altid ubegrænset',
  'No need or free' : 'Ingen eller gratis',
  'No binding, same price' : 'Ingen binding, samme pris',
  'Mobile app logbook' : 'Kørebog til mobil',
  'Manual' : 'Manuelt',
  'Limited/unlimited plans' : 'Begrænset/ubegrænset planer',
  'Yes or higher price for freedom' : 'Ja eller en højere pris for frihed',
  'Extra hardware logbook' : 'Ekstra hardware kørebog',

  // -- -- -- Getting started

  'Getting started' : 'Kom godt i gang',
  'Step' : 'Trin',
  'STEP' : 'TRIN',
  'Sign up in 1 minute' : 'Tilmeld dig på 1 min.',
  'All you need is your car\'s license plate.' : 'Alt du behøver er bilens nummerplade.',
  'Complete the registration' : 'Fuldfør registreringen',
  'You will receive a confirmation email to complete your registration.' : 'Du vil modtage en bekræftelses e-mail for at fuldføre din registrering.',
  'Connect and drive' : 'Tilslut og kør',
  'Connect your car at your preferred workshop or by yourself. Download the app.' : 'Forbind din bil ved dit værksted eller gør det på egen hånd. Download appen.',

  // -- -- -- FAQ
  'Get more answers.' : 'Få flere svar.',

  // -- -- Pricing
  'What is the number plate of your car?' : 'Hvad er bilens nummerplade?',
  'Number plate' : 'Nummerplade',
  'Which garage do you want to select as a preferred workshop?' : 'Hvilket værksted ønsker du at vælge som dit foretrukne værksted?',
  'Select the garage of your choice. We will inform them about your choice and help you coordinate how to connect your car.' : 'Vælg værksted efter eget valg. Vi vil informere dem om dit valg og hjælper dig med at koordinere, hvordan du forbinder din bil.',
  'Ready to get started?' : 'Klar til at komme i gang?',
  'We will send you a confirmation email once we process your sign up form.' : 'Vi sender dig en bekræftelses e-mail når din tilmelding er udført.',
  'By clicking on \'Sign up\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.' : 'Ved at klikke på \'Tilmeld dig\', giver du samtykke til at, OBI Plus ApS må gemme og behandle personlige oplysninger, du har angivet herover, til at levere det, du har anmodet om.',

  // -- Repair shop

  // -- -- Main

  // -- -- -- Hero
  'Create a link to vehicles and customers' : 'Opret forbindelse til køretøjer og kunder',
  'Stay informed about the condition of each connected vehicle and be closer to every customer.' : 'Hold dig informeret om tilstanden på hvert tilsluttet køretøj og vær tættere på den enkelte kunde.',
  'Take a quick tour' : 'Se det i aktion',

  // -- -- -- Contact Customers
  'Contact customers at the right time' : ' Kontakt kunderne på det rigtige tidspunkt',
  'Know that a problem has occurred before a customer calls you and take a more informed decision when advising a customer.' : 'Bliv informeret når et problem er opstået, før kunden ringer til dig, og tag en mere informeret beslutning når du rådgiver en kunde.',

  // -- -- -- Peace of mind
  'Peace of mind for each customer' : 'Tryghed for hver kunde',
  'The customers benefit from easy to use services that make their life easier and safer. We have solutions for both business and private customers that present a new convenient way to be connected to everyone.' : ' Kunderne drager fordel af brugervenlige tjenester, der gør deres hverdag nemmere og sikrere. Vi har løsninger til både erhvervs -og privatkunder, der giver en ny praktisk måde at være forbundet til alle.',

  // FIXME: Keys will not be used in the Perks section

  // -- -- -- Clients
  'Hundreds of workshops already use Vehicle Care' : 'Hundredvis af danske værksteder bruger allerede Vehicle Care',

  // -- -- Features

  // -- -- -- Hero  
  'A workshop management solution powered by data' : 'En værkstedsløsning baseret på data',

  // -- -- -- Pitch
  'Vehicle Care for you' : 'Vehicle Care for dig',
  'A workshop service for mechanics that links them to vehicles' : 'En værkstedstjeneste for mekanikere, der forbinder dem til køretøjer',

  // -- -- -- Features
  'Direct link to vehicles 24/7' : 'Direkte link til køretøjer 24/7',
  'Have remote access to relevant vehicle data like never before. It is fast, simple, and creates value for your customers.' : 'Få fjernadgang til relevante køretøjsdata som aldrig før. Det er hurtigt, enkelt og skaber værdi for dine kunder.',
  'Important warnings' : 'Vigtige advarsler',
  'Be proactive in helping customers when an undesired situation occurs. Be notified of important warnings such as engine light on, battery low, and others.' : 'Vær proaktiv i at hjælpe kunder, når en uønsket situation opstår. Bliv underrettet om vigtige advarsler som f.eks. motorlys, lavt batteri og andet.',
  // 'Service and repair logbook' : 'Service- og reparationslogbog',
  'Track, schedule and be notified when a vehicle is due for service or inspection. Have it always as a backup accessible also by vehicle owners.' : 'Spor, planlæg og få besked når et køretøj skal til service eller syn. Hav det altid som en sikkerhedskopi, hvilket også er tilgængeligt for køretøjsejere.',
  'Up-to-date maintenance' : 'Opdateret vedligeholdelse',
  'Make sure that all vehicles are well-maintained and safe to drive. Newer vehicles are being serviced properly and within service intervals to avoid issues with warranty.' : 'Sørg for, at alle køretøjer er velholdte og sikre at køre. Nyere køretøjer serviceres korrekt og inden for serviceintervaller for at undgå garantiproblemer.',
  // 'Reports' : 'Rapporter',
  'Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others' : 'Planlagte rapporter, der peger ens opmærksomhed på vigtige begivenheder såsom motorlys, kommende service mm.',

  // -- -- -- Services
  'Services for your customers' : 'Tjenester til dine kunder',
  'Bring customers closer to your workshop by giving them a useful tool in their daily life.' : 'Bring kunder tættere på dit værksted ved at give dem et nyttigt værktøj i deres daglige liv.',

  // -- -- -- -- Fleet
  'An easy to use fleet management dashboard for any fleet size' : 'Et brugervenligt betjeningspanel til flådestyring til enhver flådestørrelse',
  'Automatic trip-logging and a simple historical overview' : 'Automatisk kørselsregistrering og et simpelt historisk overblik',
  'Personalized reports specific to their unique fleet usage' : 'Skræddersyede rapporter specifikt til deres flåde',

  // -- -- -- -- Driver App
  'A mobile app for every driver that gives them a good overview' : 'En mobilapp til enhver bruger af køretøjet, der giver dem et godt overblik',
  'Convenient contact with their preferred workshop' : 'Praktisk kontakt med deres foretrukne værksted',
  'Automatic logging and categorization of trips' : 'Automatisk registrering og kategorisering af ture',

  // -- -- Pricing
  'Which customer types do you want to connect to your repair shop?' : 'Hvilke kundetyper ønsker du at forbinde til dit værksted?',
  'Write down the email we should use to answer your request.' : 'Skriv den e mail vi skal bruge til at besvare din anmodning.',
  'Business' : 'Erhverv',
  'Private' : 'Privat',
  'Empower your repair shop today.' : 'Styrk dit værksted i dag',

  'Perfect' : 'Perfekt',
  'Thank you for your interest. Write down the email we should use to answer your request.' : 'Perfekt. Tak for din interesse. Skriv venligst den email adresse vi skal bruge for at besvare din forespørgsel.',

  // -- Dealerships

  // -- -- Main

  // -- -- -- Hero
  'Connected car services for car dealerships' : 'Connected car-tjenester til bilforhandlere',
  'A better way to' : 'En bedre måde at',
  'be proactive' : 'være proaktiv',
  'manage vehicles' : 'administrere køretøjer',
  'create value' : 'skabe værdi',

  // -- -- -- Digital Services
  'Digital services for dealers and customers' : 'Digitale tjenester forhandlere og kunder',
  'A complete solution for dealerships that want to be proactive in serving their customers by leveraging vehicle data.' : 'En komplet løsning til forhandlere, der ønsker at være proaktive til betjening af deres kunder ved at udnytte køretøjsdata.',

  // -- -- -- Data Driven Vehicles
  'Data-driven vehicle management' : 'Datadrevet køretøjshåndtering',
  'Empowering the workshop department to increase customer retention and lifetime value of a customer. Know when customers need professional assistance.' : 'Styrker værkstedsafdelingen med kundefastholdelse og levetidsværdien af en kunde. Vær klar over når kunder har brug for professionel hjælp.',

  // -- -- -- Motivation
  'More value for everyone' : ' Mere værdi for alle',
  // 'Safer vehicles to drive' : 'Mere sikre biler at køre',
  'Convenient planning of new appointments' : 'Praktisk planlægning af nye aftaler',
  'Acting on time to avoid critical issues' : 'Handling til tiden for at undgå kritiske problemer',

  // -- -- Features

  // -- -- -- Hero
  'A unique dealer management solution' : 'En unik løsning til bilforhandlere',

  // -- -- -- Vehicle Care
  'Vehicle care for dealerships' : 'Vehicle Care til dig',
  'Service for remote monitoring of vehicle data and proactive customer management' : ' Tjeneste til fjernovervågning af køretøjsdata og proaktiv kundestyring',
  // -- -- -- -- Features
  'Advanced remote data access' : 'Avanceret fjernadgang',
  'Specific data coverage to the vehicles that your workshop is an expert on. Providing new insights that were not available before.' : 'Specifik datadækning til de køretøjer, som dit værksted er ekspert i. Levere indsigt som ikke var tilgængeligt før.',
  'Timely customer services' : 'Rettidig kundeservice',
  'Build a closer relationship with customers by having more possibilities to coordinate maintenance and repair.' : 'Skab et tættere forhold til kunderne ved at have flere muligheder for at koordinere vedligeholdelse og reparation.',
  'Track agreements' : 'Spor dine aftaler',
  'A unique way to track service and leasing agreements making it easier to manage operations and communication with customers.' : 'En unik måde at spore service og leasingaftaler på, hvilket gør det lettere at styre driften og kommunikationen med kunderne.',
  'Important notifications' : 'Vigtige notifikationer',
  'Leverage the power of connected car data to be notified when important events occur. Increase productivity and optimize the business.' : 'Udnyt connected car data for at blive underrettet når vigtige begivenheder opstår. Forøg produktiviteten og optimer forretningen.',
  'Integrate to other systems' : 'Integrer med andre systemer',
  'Easy to implement integrations to other relevant systems used by your departments.' : 'Nem at implementere integrationer til andre relevante systemer, der anvendes af dine afdelinger.',

  // -- -- -- Services
  // 'Services for your customers' : 'Tjenester til dine kunder',
  'Expand your dealership portfolio by offering customers new digital services' : 'Udvid din forhandlerportefølje ved at tilbyde kunderne nye digitale tjenester',

  // -- -- -- -- Fleet
  'Fleet by OBI+' : 'OBI+ Fleet',
  // 'An easy to use fleet management dashboard for any fleet size' : 'Et brugervenligt betjeningspanel til flådestyring til enhver flådestørrelse',
  // 'Automatic trip-logging and a simple historical overview' : 'Automatisk trip-logging og et simpelt historisk overblik',
  // 'Personalized reports specific to their unique fleet usage' : 'Skræddersyede rapporter specifikt til deres flåde',

  // -- -- -- -- Driver App
  'Driver App' : 'Driver App',
  // 'A mobile app for every driver that gives them a good overview' : 'En mobilapp til enhver fører, der giver dem et godt overblik',
  // 'Convenient contact with their preferred workshop' : 'Praktisk kontakt med deres foretrukne værksted',
  // 'Automatic logging and categorization of trips' : 'Automatisk registrering og kategorisering af ture',

  // -- -- Pricing
  'Empower your dealership today.' : 'Styrk din bilforretning i dag.',
  'Which customer types do you want to connect to your dealership?' : 'Hvilke kundetyper vil du gerne tilslutte til din bilforretning?',

  // -- Leasing

  // -- -- Main

  // -- -- -- Hero
  'Connected car services for leasing' : 'Connected car løsning for leasingselskaber',
  'A smart solution to' : 'En smart løsning til at',
  // 'manage vehicles' : 'administrere køretøjer',
  'ease administration' : 'lette administrationen',
  'add value' : 'tilføje værdi',

  // -- -- -- Simplify
  'Simplify vehicle management' : 'Simplificer administrationen af køretøjer',
  'Data-driven vehicle management solution for leasing companies. Leverage up-to-date insights into the condition of vehicles and the status of agreements.' : ' Datadrevet løsning til køretøjshåndtering for leasingselskaber. Udnyt up-to-date indblik i tilstanden på køretøjer og status for aftaler.',

  // -- -- -- Expand Portofolio
  'Expand the service portfolio' : 'Udvid serviceporteføljen',
  'Provide customers with a complete offering by introducing new digital services that customers can benefit from when leasing vehicles.' : 'Giv kunderne et komplet tilbud ved at introducere nye digitale tjenester, som kunderne kan drage fordel af, når de leaser køretøjer.',

  // -- -- -- Motivation
  'Valuable for your customers' : 'Værdifuldt for dine kunder',
  'Ease their responsibilities' : 'Formindsk deres ansvarsområder',
  'Take advantage of new services' : 'Udnyt de nye tjenester',
  'Take action on time to avoid critical issues' : 'Handling til tiden for at undgå kritiske problemer',

  // -- -- Features

  // -- -- -- Hero
  'A solution that makes vehicle management easier for leasing companies' : 'En løsning, der gør køretøjsadministrationen lettere for leasingselskaber',

  // -- -- -- Vehicle Care
  'Vehicle care for leasing' : 'Vehicle Care til leasing',
  'Powered by relevant vehicle data to optimize business operations' : 'Drives af relevante køretøjsdata til at optimere virksomhedens drift',
  // -- -- -- -- Features
  'Relevant vehicle data' : 'Relevant køretøjsdata',
  'Access to data about mileage, service maintenance, vehicle status and other parameters.' : 'Adgang til data om kilometertal, servicevedligeholdelse, køretøjsstatus og andre parametre.',
  'Timely reporting' : 'Rettidig rapportering',
  'Be informed on time through tailored reports about improper usage like crossing country borders or driving with hazardous vehicle issues.' : 'Bliv informeret til tiden gennem skræddersyede rapporter om forkert brug, såsom krydsning af landegrænser eller kørsel når der er fejl på bilen.',
  'Manage agreements' : 'Administrer aftaler',
  'An automated tool to track leasing agreements making it easier to manage operations and communication with customers.' : 'Et automatiseret værktøj til at holde øje med leasingaftaler, hvilket gør det lettere at styre driften og kommunikationen med kunderne.',
  // 'Communication' : 'Kommunikation',
  'Improve customer service by communicating with customers through a new convenient channel.' : ' Forbedre kundeservice ved at kommunikere med kunderne gennem en ny kommunikationskanal.',
  'Integrate data to other systems' : 'Integrer data til andre systemer',
  'Optimize your leasing business by leveraging data and insights.' : 'Optimer din leasingforretning ved at udnytte data og indsigt.',

  // -- -- -- Services
  // 'Services for your customers' : 'Tjenester til dine kunder',
  'Offering customers new digital services as added value' : 'Tilbyder kunder nye digitale tjenester som merværdi',
  // -- -- -- -- Fleet
  // 'An easy to use fleet management dashboard for any fleet size' : 'Et brugervenligt betjeningspanel til flådestyring til enhver flådestørrelse',
  // 'Automatic trip-logging and a simple historical overview' : 'Automatisk trip-logging og et simpelt historisk overblik',
  // 'Personalized reports specific to their unique fleet usage' : 'Skræddersyede rapporter specifikt til deres flåde',

  // -- -- -- -- Driver App
  // 'A mobile app for every driver that gives them a good overview' : 'En mobilapp til enhver fører, der giver dem et godt overblik',
  // 'Convenient contact with their preferred workshop' : 'Praktisk kontakt med deres foretrukne værksted',
  // 'Automatic logging and categorization of trips' : 'Automatisk registrering og kategorisering af ture',

  // -- -- Pricing
  'Empower your business today' : 'Styrk din forretning i dag',
  'Which vehicle types do you want to connect to your leasing company?' : 'Hvilken type køretøjer vil du gerne tilslutte til dit leasingfirma?',
  'How many vehicles do you want connected?' : 'Hvor mange køretøjer vil du gerne tilslutte?',

  // -- About Us

  // -- -- Top Section
  // Left
  'The platform enabling the fastest delivery of connected car services.' : 'Platformen der muliggør en hurtig opstart med connected car tjenester.',
  // Right
  'Who are we?' : 'Hvem er vi?',
  'OBI+ is a tech company offering a cloud-based platform that provides the infrastructure and digital products to make connected car services accessible to everyone.' : 'OBI+ er en teknologisk virksomhed, der leverer en applikationsplatform til tilsluttede biltjenester.',
  'At its core, OBI+ is driven by a great team of engineers, designers, marketeers with a mission to empower innovation through a suite of services built to enable personalized digital experiences for every car driver, fleet operator, mechanic, and any other service provider who recognizes the potential for a safer, better, smarter future powered by data.' : 'OBI+ drives af et team af ingeniører, designere, marketingfolk med en mission om at styrke innovation gennem en række tjenester, der er bygget for at muliggøre personaliserede digitale oplevelser for enhver bilchauffør, flådeoperatør, mekaniker og enhver anden tjenesteudbyder, der genkender potentialet for en mere sikker, bedre og smartere fremtid drevet af data.',

  // -- -- Vision
  // Left
  'Our vision' : 'Vores vision',
  'Our vision is to enable a safer, better, smarter future by making connected car services accessible for everyone.' : 'Vores vision er at muliggøre en sikrere, bedre og smarter fremtid ved at gøre connected car tjenester tilgængelig for alle.',
  'We aim to be the leading provider of connected car services. Thus, we will be progressively adding integrations to new smart platforms, applications, and other software services. Our commitment is to continuously add capabilities to the OBI+ platform over time and connect the leading technology of tomorrow.' : 'Vi sigter mod at være den førende udbyder af connected car tjenester. Dette betyder, at vi løbende tilføjer integrationer til nye, smarte platforme, applikationer og andre softwaretjenester. Vores forpligtelse er at forbedre OBI+ platformen løbende og at connecte med morgendagens førende teknologi.',
  // Right
  'For a safer, better, smarter future powered by data.' : 'For en mere sikrere, bedre og smartere fremtid drevet af data.',

  // -- -- Digital Word
  'The digital world powered by OBI+' : 'Den digitale verden drevet af OBI+',
  'Next generation of connected car services in one place. Welcome to a world of digital touchpoints that create value, deliver insights and enable growth.' : 'Næste generation af connected car tjenester samlet et sted. Velkommen til en verden af digitale berøringspunkter, der skaber værdi, leverer indsigt og muliggør vækst.',

  // -- -- Partners
  'Commercial partners' : 'Kommercielle partnere',
  'We are here for those who refuse to settle, who believe in the future of connected mobility. Because today\'s world deserves a partner that is able to adapt fast, recognizes the needs of different types of vehicle users, and is ready to push into new exciting spaces.' : 'Vi er her for dem der ikke vil nøjes og som tror på en fremtid med tilsluttet mobilitet. Nutidens verden fortjener en partner, der er i stand til at tilpasse sig hurtigt, anerkender behovene hos forskellige typer køretøjsbrugere og som er klar til udforske nye og spændende retninger i den digitale verden.',
  'Repair shop concepts' : 'Værksted koncepter',
  'Serviced fleet management' : 'Serviceret flådestyring',
  'Want to become a partner?' : 'Vil du gerne være en partner?',
  'Contact our business team' : 'Kontakt vores team',
  'In-vehicle data into complete software solutions' : 'In-vehicle data into complete software solutions',
  'Edge computing & off-the-shelf connected services' : 'Edge computing & off-the-shelf connected services',
  'Car data and connected car services as a complete package' : 'Car data and connected car services as a complete package',

  // -- -- Partners Use Cases
  'Let\'s work together to make your project a reality' : 'Lad os arbejde sammen for at gøre dit projekt til virkelighed',
  'We are already supporting a variety of commercial use cases and we continue to search for new ideas and concepts that drive better digital experiences. Let us know what you want to do.' : 'Vi støtter allerede en række sager med kommerciel brug, og vi fortsætter med at søge efter nye ideer og koncepter, der skaber bedre digitale oplevelser. Lad os endelig vide, hvad hvad du har lyst til at gøre.',
  'Remote vehicle management' : 'Fjernstyring af køretøjer',
  'Usage-based services fueled by car data' : 'Brugerbaserede tjenester, der er drevet af bildata',
  'Innovative on-demand car-related services' : 'Innovative on-demand bilrelaterede tjenester',
  'Unique driver assistant services' : 'Unikke førerassistent tjenester',
  'Smart location-based services' : 'Smarte placeringsbaserede tjenester',

  // -- -- Contact Us
  'Write to' : 'Skriv til',
  'Ring' : 'Ring',
  'Address' : 'Adresse',
  'Join us' : 'Bliv en del af os',
  'Blog' : 'Blog',

  // -- Try now
  'Perfect. Which email should we use to create your account?' : 'Perfekt. Hvilken email skal vi bruge?',
  'Perfect. Thank you for your interest.' : 'Perfekt. Mange tak for din interesse.',
  'Send': 'Indsend',
  'By clicking \'Send\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.' : 'Ved at klikke på \'Indsend\', giver du samtykke til at, OBI Plus ApS må gemme og behandle personlige oplysninger, du har angivet herover, til at levere det, du har anmodet om.',
  'Our sales team will follow up soon.' : 'Vores salgsteam vender tilbage til dig hurtigst muligt.',
  'As soon as we have received your information, we will create an account in OBI+ for you. You can get started right away! We will contact you soon to answer any questions and make sure that you have a great start.' : 'Så snart vi har modtaget dine oplysninger vil du blive oprettet i OBI+. Du kan herefter gå i gang med det samme! Vi kontakter dig snarest for at besvare evt. spørgsmål og sikre at du kommer godt i gang.',

  // -- Blog
  'OBI + is a Danish IT company that provides an application platform for connected car services.' : 'OBI+ er en dansk IT virksomhed, som giver en applikationsplatform for tilsluttede biltjenester.',
  'Our services bridge the gap between what service providers do today and what vehicle users want. Here we share knowledge about the automotive industry, connected car technologies, fleet management, tips & use cases that you can take advantage of.' : 'Vores tjenester bygger bro mellem det, tjenesteudbydere gør det i dag, og hvad køretøjsbrugere ønsker. Her deler vi de bedste tips og brugssager, som du kan bruge til din fordel.',
  'Posts' : 'Poster',
  'of' : 'af',

  // -- Sign up
  'Choose which of the services you want to see in action' : 'Vælg hvilke af de tjenester, du gerne vil se i aktion',
  'You can choose 1 or more of the services below.' : 'Du kan vælge 1 eller flere af nedenstående tjenester.',

  'Web-based fleet management' : 'Webbaseret flådestyring',
  'Service for every driver' : 'Tjeneste for enhver bilist',

  // -- ROI calculators

  'Fleet ROI calculator' : 'ROI beregner flådestyring',
  'Logbook ROI calculator' : 'ROI beregner kørebog',

  'It pays to use' : 'Det betaler sig at',
  'our services' : 'bruge vores tjenester.',
  'Enter your consumption' : 'Indtast dit forbrug',
  'Consumption overview' : 'Oversigt af forbrug',

  // -- -- Fleet

  'Number of employees/vehicles running' : 'Antal kørende medarbejdere/køretøjer',
  'Number of routes per day per employee/vehicle' : 'Antal ruter pr. dag pr. medarbejder',
  'Administrative work on one\'s fleet per day' : 'Administrationsarbejde vedrørende ens flåde pr. dag',
  'Employee hourly wage' : 'Medarbejdernes timeløn',

  // -- -- Logbook
  'Number of routes per day per vehicle' : 'Antal ruter pr. dag pr. køretøj',
  'Administrative work on similar vehicles per month' : 'Administrationsarbejde vedrørende ens køretøjer pr. måned',
  'Hourly rate' : 'Timeløn',

  // -- -- General
  
  'Time consumption for driving registration per route' : 'Tidsforbrug for kørselsregistrering pr. rute',
  'Calculate' : 'Beregn',

  'Time consumption per day' : 'Tidsforbrug pr. dag',
  'Time consumption per month (20 days)' : 'Tidsforbrug pr. måned (20 dage)',
  'Spending per month' : 'Udgift pr. måned',
  'Expenditure per year' : 'Udgifter pr. år',
  'Savings per year using our fleet service' : 'Din besparelse pr. år',

  // -- Use cases
  'Empowering innovation and exploring new exciting spaces.' : 'Styrker innovation og udforsker nye spændende områder', 
  'Vehicle connectivity is soon to be a requirement for any modern fleet manager, repair shop, roadside assistance, and other service providers.' : 'Køretøj tilslutning er snart et krav for enhver moderne flådeadministrator, værksted, vejhjælp og andre serviceudbydere.',
  'The remote link to vehicles is a fast, simple and value-adding opportunity to create a better experience for drivers. In today’s online-dominated world, people are increasing expectations towards their service providers. Remove access to vehicle data will be the new normal to which everyone shall adapt.' : 'Fjernforbindelsen til køretøjer er en hurtig, enkel og værdiskabende mulighed for at skabe en bedre oplevelse for bilister. I dagens online-domineret verden øger folk forventningerne til deres tjenesteudbydere. Fjernadgang til køretøjsdata vil være den nye normal, som alle skal tilpasse sig.',
  'Schedule and automate all regular maintenance tasks, no matter if it’s about own vehicles or customer vehicles.' : 'Planlæg og automatiser alle regelmæssige vedligeholdelsesopgaver, uanset om det drejer sig om egne køretøjer eller kundebiler.',
  'By using the OBI+ services, fleet managers can increase efficiency and maximise vehicle update, workshops can be proactive and contact customers at the right time, while drivers should not feel helpless when the service light turns on. OBI+ adds recommended maintenance schedules directly to make daily life better and smarter.' : 'Ved at bruge OBI+ tjenesterne kan flådeadministratorer øge effektiviteten og maksimere opdateringen af køretøjer, værksteder kan være proaktive og kontakte kunder på det rigtige tidspunkt, mens bilister ikke skal føle sig hjælpeløse, når servicelampen tændes. OBI+ tilføjer anbefalede vedligeholdelsesplaner direkte for at gøre hverdagen bedre og smartere.',
  'Store and view any vehicle-related documents in one place. An organised order of events and information such as what, when and who performed service is an important part of the ownership.' : 'Opbevar og se alle køretøjsrelaterede dokumenter et sted. En organiseret rækkefølge af begivenheder og information såsom hvad, hvornår og hvem der udførte en service er en vigtig del af ejerskabet.',
  'Other documents like leasing or insurance agreements come handy when an unexpected situation occurs. Having information in a digital format helps to keep track and make informed decisions as well as sharing internally or with potential buyers.' : 'Andre dokumenter som leasing eller forsikringsaftaler er nyttige, når der opstår en uventet situation. At have information i et digitalt format hjælper med at holde styr på informationerne og træffe informerede beslutninger. Del eventuelt internt i organisationen eller med potentielle købere.',
  'Use video or chat to communicate better in a number of cases. For instance, when a vehicle needs repair, often the problem is unclear. In such cases the mechanic must investigate the issue and communicate with the owner.' : 'Brug video eller chat til at kommunikere i forskellige sammenhænge. For eksempel når et køretøj skal repareres, er problemet ofte uklart. I sådanne tilfælde skal mekanikeren undersøge problemet og kommunikere med ejeren.',
  'Why not make it easier for the owner to understand by recording a video and sending it seamlessly? Or if as a driver, the issue appears while on the road. Why not having an expert assisting remotely? Access to options for digital communication should be simple and straightforward.' : 'Hvorfor ikke gøre det lettere for ejeren at forstå problemet gennem videooptagelse, som ejeren kan modtage problemfrit? Eller hvis et problem opstår hos dig som bilist, mens du er på farten. Hvorfor ikke have en ekstern ekspert, der kan hjælpe dig? Adgang til digital kommunikation skal være enkel og ligetil.',
  'More efficient daily operations for any business operating with vehicles, no matter the size. Fleet management is often mistaken with vehicle tracking. However, tracking is only one of the aspects that could improve operations.' : 'Mere effektiv daglig drift for enhver virksomhed, der opererer med køretøjer, uanset størrelse. Flådestyring forveksles ofte med sporing af køretøjer.',
  'Fleet management combines all tasks of a fleet owner/manager, from selecting the vehicles that fit the operations, operating these on a daily basis, to selling those at some point. Throughout all that time, there is a need to complete mandatory vehicle-related tasks like service and maintenance, fuel management, security, manage responsibilities of employees, tracking usage, and more. Managing the fleet operations should be easier and more convenient.' : 'Sporing er dog kun et af aspekterne, der kan forbedre den daglige drift. Flådestyring kombinerer alle opgaver, som en flådeejer/administrator har, alt fra udvælgelse af køretøjer, der passer til driften, og at betjene disse dagligt, til at eventuelt sælge dem på et tidspunkt. Gennem denne periode er der behov for at udføre obligatoriske køretøjsrelaterede opgaver som service og vedligeholdelse, brændstof, sikkerhed, administrere ansattes ansvarsområder, følge med i hvordan hvordan køretøjerne bliver brugt og mere. Håndtering af flådens drift skal være lettere og mere praktisk.',
  'Vehicle owners should be aware of ways that can help them to improve their driving. Through the use of data, the opportunity for greener driving is more accessible to everyone.' : 'Bilejere bør være opmærksomme på måder, der kan hjælpe dem med at forbedre deres kørsel. Gennem brug af data er muligheden for grønnere kørsel mere tilgængelig for alle.',
  'From a driver perspective, there could be ways to reduce fuel consumption or improve driving habits by becoming aware of events as a part of the driving. From a fleet manager perspective, one can improve the overall fleet performance by following average fuel consumption, idling events and vehicle utilization to reduce CO2 emissions.' : 'Fra en fører perspektiv kan der være områder hvor det er muligt at reducere brændstofforbruget eller forbedre kørselsvanerne eller kørdselsadfærd ved at blive opmærksom på begivenheder som er en del af kørslen. Fra et flådeadministrator perspektiv kan man forbedre den samlede ydelse af flåden ved at følge det gennemsnitlige brændstofforbrug, tomgangshændelser og udnyttelsen af flåden for at reducere CO2-udledningen.',
  'The primary reasoning for having a car is to get from A to B. It cannot be expected that car owners are experts in how a vehicle functions or what it needs are.' : 'Den primære årsag for at have en bil er at man kan komme fra A til B. Det kan ikke forventes, at bilejere er eksperter i hvordan et køretøj fungerer eller hvad det har brug for.',
  'For optimal satisfaction, owners want a safe and reliable vehicle, minimal efforts on car-related tasks, no worries in case of issues, and all that enforced by online-dominated experience. The service provider should be closer to this scenario in order to keep customers satisfied.' : 'For optimal tilfredshed ønsker bilejere et sikkert og pålideligt køretøj, minimal indsats på bilrelaterede opgaver, ingen bekymring i tilfælde af problemer og alt det der håndhæves af en online-domineret oplevelse. Tjenesteudbyderen skal være tættere på dette scenarie for at holde kunderne tilfredse.',
  'Ownership of electric vehicles is new to almost everyone. It has its characteristics and specific needs.' : 'Elbiler er nyt for rigtigt mange mennesker. Det har sine egenskaber og specifikke behov.',
  'The number of electric vehicles will grow tremendously in the next few years and with that the interest in digital services that make EV ownership more convenient for private owners, businesses or public institutions. Both the EV owners and their service providers would require a new set of tools to help them.' : 'Antallet af elbiler vil stige enormt i de næste par år og dermed også interessen for digitale tjenester, der vil gøre elbil-ejerskabet mere praktisk for private ejere, virksomheder og offentlige institutioner. Både ejere af elbiler og deres tjenesteudbydere vil have brug for et nyt sæt værktøj til at hjælpe dem.',
  
  
  // -- Image ALT descriptions
  // -- -- Home
  'A suite of connected car services' : 'A suite of connected car services',
  'Mechanic from auto repair shop using connected car services' : 'Mechanic bruger connected car thenester i værkstedet',
  'Vehicle Care connected car platform by Obiplus' : 'Vehicle Care connected car platform by Obiplus',
  'Fleet operator manager using web-based fleet management service' : 'Flådeadministrator sporer, vedligeholder og administrerer køretøjer',
  'Fleet management by Obiplus' : 'Fleet management by Obiplus',
  'Mobile application Drive by Obiplus' : 'Mobile application Drive by Obiplus',
  'Car owner using Drive app by Obiplus' : 'Bilist bruger bil-app med automatisk kørebog',

  // -- -- Platform
  'Hardware-free link to major car brands' : 'Direkte hardwarefri forbindelse til store bilmærker',

  // -- -- Services
  'OBI+ provides digital services through connected car' : 'Connected car tjenester designet til at løse køretøjsrelaterede opgaver',

  // -- -- Customers -> Driver
  'Car application for every driver' : 'Bil-app til enhver bilist',
  'Mobile app for every car driver' : ' En app til din bil',

  // -- -- Customers -> Fleet
  'Fleet management solution for everyone within the company' : 'Flådestyring til virksomheder i Danmark',
  'Fleet management for any fleet size and vehicle' : 'Flådestyring til enhver flådestørrelse',
  'Fleet management solution for your vehicles' : ' En flådestyringstjeneste til enhver flådestørrelse',
  'Connect your car, van, or trailer' : 'Forbind din bil, varevogn eller trailer',

  // -- -- Customers -> Repair shops (Vehicle care)
  'A workshop management solution powered by data.' : ' En værkstedsløsning baseret på data.',

  // -- -- Customers -> Dealerships
  'A unique solution for dealerships' : 'A unique solution for dealerships',

  // -- -- Use cases
  'Use cases of how connected car solutions used' : 'Innovative og digitale køretøjsløsninger',
  'Remote vehicle monitoring through connected car services' : 'Fjernmonitorering af køretøj gennem connected car tjenester',
  'Preventive maintenance - shedule and automate maintenance' : 'Forebyggende vedligeholdelse - planlæg og automatiser alt vedligeholdelse',
  'Store and view all vehicle-related documents' : 'Se alle køretøjsrelaterede dokumenter et sted',
  'Digital communication through video or chat' : 'Digital kommunikation gennem video eller chat',
  'Manage all fleet-related operations on a connected car platform' : 'Flådestyring for virksomheder',
  'Reduce CO2 emissions for your fleet' : ' Spar på brændstof omkostninger med grøn kørsel',
  'Minimal efforts on car-related tasks' : 'Minimal indsats på bilrelaterede opgaver',
  'EV ownership and digital services for electric vehicles' : 'Digitale tjenester for el-køretøjer',


  // -- Footer
  'See our blog' : 'Se vores blog',
  'Telephone number' : 'Ring',
  'Contact' : 'Kontakt',
  'General inquiries' : 'Generelle forespørgsler',
  'Monday to Friday 09:00 to 16:00' : 'Mandag til Fredag 09.00 til 16.00 (CET)',
  'Fleet managers' : 'Flådeadministratore',
  'The blog' : 'Vores blog',

  //Landing pages
  // -- Fleet -> Sign up
  'Fill out the form' : 'Udfyld formularen',
  'As soon as we have received your information, we will create an account in Fleet for you. You can get started right away.' : 'Så snart vi har modtaget dine oplysninger vil du blive oprettet i Fleet. Du kan herefter gå i gang med det samme!',
  'We will contact you soon to answer any questions and make sure that you have a great start.' : 'Vi kontakter dig snarest for at besvare evt. spørgsmål og for at sikre os at du kommer godt i gang.',
  'Try for free in 30 days' : 'Prøv gratis i 30 dage',
  'When you have submitted the form and we have received your information, you can test the service for free.' : 'Når du har udfyldt formularen og vi har modtaget dine oplysninger kan du teste tjenesten gratis.',
  'End of the test period' : 'Efter endt prøveperiode',
  'When your test period finishes, we will contact you to discuss how you want to use Fleet in the future.' : 'Når din prøveperiode slutter, kontakter vi dig for at aftale hvordan du ønsker at bruge Fleet fremover.',

  // URLs
  '_url:_' : 'https://da.obiplus.com',
  '_url:main' : '/',
  '_url:platform' : '/connected-car-platform',
  '_url:services' : '/connected-car-tjenester',
  '_url:about-us' : '/om-obiplus',
  '_url:fleet-management' : '/flaadestyringsloesning',
  '_url:fleet-management-features' : '/flaadestyring-til-virksomheder',
  '_url:fleet-management-why' : '/hvorfor-flaadestyring',
  '_url:fleet-management-pricing' : '/flaadestyring-pris',
  '_url:fleet-management-pricing-tables' : '/flaadestyring-fleet-pris',
  '_url:drivers' : '/bilister',
  '_url:drivers-pricing' : '/tilmeld-dig-bil-app',
  '_url:repair-shop' : '/vehicle-care',
  '_url:repair-shop-features' : '/vehicle-care-funktioner',
  '_url:repair-shop-pricing' : '/vehicle-care-pris',
  '_url:repair-shop-pricing-tables' : '/vehicle-care-tjeneste-pris',
  '_url:dealership' : '/connected-car-til-bilforhandler',
  '_url:dealership-features' : '/loesning-til-bilforhandler',
  '_url:dealership-pricing' : '/bilforhandler-pris',
  '_url:leasing' : '/connected-car-til-leasing',
  '_url:leasing-features' : '/loesning-til-leasing',
  '_url:leasing-pricing' : '/leasing-pris',
  '_url:sign-up' : '/tilmeld-dig',
  '_url:blog' : '/blog',
  '_url:404' : '/ikke-funderet',

  '_url:fleet-roi' : '/roi-beregner-flaadestyring',
  '_url:logbook-roi' : '/roi-beregner-koerebog',

  '_url:cookie-policy' : '/cookie-policy',
  '_url:privacy-policy' : '/privacy-policy',
  '_url:fleet-terms' : '/fleet-salgsbetingelser',

  '_url:use-cases' : '/use-cases',

  // Landing pages

  // -- Landing Drive
  '_url:landing-driver' : '/drive-app',
  '_url:landing-driver-signup' : '/tilmeld-dig-drive-app',

  // -- Landing Fleet
  '_url:landing-fleet' : '/flaadestyring-tjeneste',
  '_url:landing-fleet-features' : '/flaadestyring-funktioner',
  '_url:landing-fleet-why' : '/flaadestyring-fordele',
  '_url:landing-fleet-pricing' : '/pris-flaadestyring',
  '_url:landing-fleet-signup' : '/tilmeld-dig-fleet',
  '_url:landing-fleet-demo' : '/demo-fleet',

  // -- Landing VC
  '_url:landing-vc' : '/vehicle-care-tjeneste',
  '_url:landing-vc-features' : '/vehicle-care-loesning',
  '_url:landing-vc-pricing' : '/vehicle-care-priser',
  '_url:landing-vc-signup' : '/vehicle-care-bliv-partner',

  // IDs in URL
  '_hash:main' : 'main',
  '_hash:vehicle-care' : 'vehicle-care',
  '_hash:fleet' : 'fleet',
  '_hash:driver-app' : 'driver-app',
  '_hash:for-you' : 'til-dig',
  '_hash:for-customers' : 'til-kunde',

  // IDs in About Us
  '_hash:company' : 'firma',
  '_hash:vision' : 'vision',
  '_hash:partners' : 'partnere',
  '_hash:contact-us' : 'kontakt-os',
  
  //IDs in Platform
  '_hash:saas-tools' : 'saas-tools',
  
  // IDs in Use cases
  '_hash:remote-monitoring' : 'remote-monitoring',
  '_hash:fleet-management' : 'fleet-management',
  '_hash:preventive-maintenace' : 'preventive-maintenace',
  '_hash:green-driving' : 'green-driving',
  '_hash:digital-communication' : 'digital-communication',
  '_hash:customer-satisfaction' : 'customer-satisfaction',
  '_hash:digital-documentation' : 'digital-documentation',
  '_hash:ev-ownership' : 'ev-ownership',

  // IDs in fleet features
  '_hash:different-vehicle-types' : 'different-vehicle-types',
  '_hash:fleet-dashboard' : 'fleet-dashboard',
  // '_hash:driver-app' : 'driver-app', // This one is duplicated above

  // IDs in Landing Drive
  '_hash:getting-started' : 'kom-godt-i-gang',

  //Traffilog specific stuff

  //IDs in Solutions
  '_hash:transition-to-evs' : 'transition-to-evs',
  '_hash:fleet-administration' : 'fleet-administration',
  '_hash:keyless-access' : 'keyless-access',
  '_hash:collision-detection' : 'collision-detection',
  '_hash:driver-management' : 'driver-management',
  '_hash:maximize-uptime' : 'maximize-uptime',
  '_hash:operational-efficiency' : 'operational-efficiency',
  '_hash:advanced-monitoring' : 'advanced-monitoring',
}