import React from 'react';
import clsx from 'clsx';
import Item from './Item';
import styles from './styles.module.sass'

export default ({
  items,
  subitems,
  page,
  subpage,
  open,
  onClose,
  onClick
}) => (
  <>
    <div role='menu'
      className={clsx(styles.burger, !open && styles.burgerClosed)}>
      {subitems
        ? subitems.map((el, key) => (
            <Item key={`subitem-${key}`}
              inSub
              onClick={onClose}
              selected={el.name === subpage}
              {...el}/>
          ))
        : null}
      {items.map((el, key) => (
        <Item key={`item-${key}`}
          onClick={onClose}
          selected={el.name === page}
          {...el}/>
      ))}
      {/* {subpage ? <div className={styles.subSeparator}/> : null} */}
    </div>
    <div onClick={open ? onClose : onClick} onKeyDown={open ? onClose : onClick} role={'button'} tabIndex={0} aria-label={'Toggle menu'}
      className={clsx(styles.burgerUnderlay, open && styles.burgerUnderlayActive)}/>
  </>
)