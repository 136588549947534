import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../locale';
import Typography from '../Typography';
import Icon from './Icon';
import styles from './styles.module.sass';

export default () => (
  <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
      className={styles.contactContainer}>
      <Typography variant='h4' align='left' color='white'
        className={styles.contactTopText}>
        {i18n('Contact us')}
      </Typography>
      <br />
      <Typography variant='subtitle2' align='left' weight='light' color='white'>
        {i18n('Telephone number')}: +45 96 73 76 73
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light'  color='white'>
        {i18n('Monday to Thursday 09:00 to 16:00 (CET) / Friday 09:00 to 15:30 (CET)')}
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light'  color='white'>
        info@traffilog.dk
      </Typography>
      <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}
        className={styles.iconContainer}
        justifyContent='flex-start' alignContent='flex-start' alignItems='flex-start'>
        {/* <Icon name='youtube' url='https://www.youtube.com/channel/UCiUjVJA128QogrhqvuL71nw/'/> */}
        {/* <Icon name='twitter' url='https://twitter.com/obi_plus'/> */}
        <Icon name='linked-in' url="https://www.linkedin.com/company/traffilognordic/"/>
        <Icon name='facebook' url='https://www.facebook.com/TraffilogNordic'/>
        <Icon name='email' noTarget={false} url='mailto:info@traffilog.dk'/>
      </Grid>
    </Grid>
  </>
);