import i18n from '../../../locale';

export default [{
    heading: i18n('Platform'),
    pages: [{
      name: i18n('Platform overview'),
      url: i18n('_url:platform')
    }, {
      name: i18n('Data sources'),
      url: `${i18n('_url:platform')}#${i18n('_hash:data-sources')}`
    }, {
      name: i18n('SaaS tools'),
      url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`
    }]
  },{
  heading: i18n('Solutions'),
  pages: [{
    name: i18n('Fleet management'),
    url: `${i18n('_url:solutions')}#${i18n('_hash:fleet-management')}`
  }, {
    name: i18n('Shared mobility'),
    url: `${i18n('_url:solutions')}#${i18n('_hash:mobility')}`
  }, {
    name: i18n('Flex mobility'),
    url: `${i18n('_url:solutions')}#${i18n('_hash:mobility')}`
  }]
}, {
  heading: i18n('About us'),
  pages: [{
    name: i18n('The company'),
    url: `${i18n('_url:about-us')}`
  }, {
    name: i18n('Our story'),
    url: `${i18n('_url:about-us')}#${i18n('_hash:our-story')}`
  }, {
    name: i18n('Join us'),
    url: `${i18n('_url:about-us')}#${i18n('_hash:join-us')}`
  }]
}, {
  heading: i18n('Resources'),
  pages: [{
    name: i18n('Book an intro meeting'),
    url: `${i18n('_url:lets-connect')}`
  }, {
    name: i18n('Become a partner'),
    external: true,
    url: 'mailto:info@traffilog.dk'
  }, {
    name: i18n('Product tour'),
    url: `${i18n('_url:lets-connect')}`
  }, {
    name: i18n('Terms'),
    external: true,
    url: `${i18n('_url:privacy-policy')}`
  }, {
    name: i18n('Privacy'),
    external: true,
    url: `${i18n('_url:privacy-policy')}`
  }]
}]